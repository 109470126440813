import React from "react"
// import { Link } from "gatsby";
import { css } from "styled-components"
import Layout from "../layouts/layout"
// import Image from "../components/image";
import Seo from "../components/seo"
import { UiText, Heading } from "../components"
const AboutPage = () => (
  <Layout>
    <Seo title="About" />
    <div
      css={css`
        position: relative;
        max-width: 960px;
        margin: auto;
        padding: 40px 0px;
      `}
    >
      <Heading level={1} as="h1" variant="h1">
        About Me
      </Heading>

      <UiText variant="content">
        Hi, my name is Souleymane Dembele; I am a Computer Hardware and Software
        Engineer (HW/SW) with a strong fundamental computer science, computer
        architecture and engineering concepts. Currently, I am pursing my
        computer engineering degree at the University of Washington, Tacoma
        Campus. My wife and I live in the Greater Seattle Area ☔ in the
        beautiful State of Washington 🌲. I have a passion for RTL design and
        implementation, circuit design, Programing, Hardware, and Software
        integration. I also have a passion for Design systems, serverless
        technologies such as AWS, Azure, and Google Cloud, Data Structure,
        Algorithms, and Artificial Intelligence. To grow my Hardware and
        Software skills, I focus on building Projects with custom PCB, FPGA,
        ASIC, and Microcontrollers. I also build JAM (Javascript API's Markup)
        stack applications. Outside of programming and circuit design and
        manufacturing, I like to watch TV, spend time with my family and
        friends, learn new skills, and do fun stuff.
      </UiText>
      <Heading level={2} as="h2" variant="h2">
        Programming Skills
      </Heading>
      <dl>
        {/* <dt>Computer ⌨️</dt> */}
        {/* <dt>Computer</dt>
        <dd>
          <UiText variant="content">
            Macbook Air 13' 2015 | Dell Alienware R2 17.3' 2015
          </UiText>
        </dd> */}
        {/* <dt>Technologies 🔌</dt>
        <dd>
          <UiText variant="content">
            JavaScript • React • Redux • Apollo • Node • Express • MySql •
            GraphQL •
          </UiText>
        </dd> */}
        <UiText as="dt" variant="contentBold">
          Hardware Description Languages(HDL)
        </UiText>
        <UiText as="dd">Verilog • System Verilog • Vhdl</UiText>
        <UiText as="dt" variant="contentBold">
          Software Programing Languages
        </UiText>
        <UiText as="dd">
          C • C++ • Arm Assembly • RISC V Assembly • MIPS Assembly • x86-64
          Assembly • JavaScript • Web Assembly • Java • Python • HTML5 • CSS3 •
          SASS • Swift • Objective C • PHP • Latex • Matlab • Git
        </UiText>
        <UiText as="dt" variant="contentBold">
          Frameworks
        </UiText>
        <UiText as="dd">
          Electron • React Native • AngularJS • Qt • Juce Framework • Django •
          Spring • Java Swing • JUnit • Jasmine
        </UiText>
        <UiText as="dt" variant="contentBold">
          Libraries
        </UiText>
        <UiText as="dd">
          React • Redux • jQuery • LoDash • D3js • Numpy • Sckit learn • Pandas
          • Matplotlib • Scipy • SDL2 (C++)
        </UiText>
        <UiText as="dt" variant="contentBold">
          Servers
        </UiText>
        <UiText as="dd"> Nginx • Apache • NodeJS</UiText>
        <UiText as="dt" variant="contentBold">
          Databases
        </UiText>
        <UiText as="dd">MySql • PostGres</UiText>
        <Heading level={2} as="h2" variant="h2">
          Hardware Development Board
        </Heading>
        <UiText as="dt" variant="contentBold">
          FPGA
        </UiText>
        <UiText as="dd">
          {" "}
          Altera: Altera Terasic DE2-115 Development Board Cyclone IV FPGA •
          Xilinx: Digilent Basys 3 Artix-7 FPGA{" "}
        </UiText>
        <UiText as="dt" variant="contentBold">
          Micro controller
        </UiText>
        <UiText as="dd">
          STM32 • Rasberry Pie and Pie Pico RP2040 • Arduino • ESP32{" "}
        </UiText>
        <UiText as="dt" variant="contentBold">
          Micro processor
        </UiText>
        <UiText as="dd">Arm Cortex M0 • Arm Cortex M4 • Atmega 328</UiText>
        {/* <UiText as="dt" variant="contentBold">Micro processor</UiText>
        <UiText as="dd"> </UiText> */}
        <Heading level={2} as="h2" variant="h2">
          Circuit Simulation, EDA, CAD, and CAM Tools
        </Heading>

        <UiText as="dt" variant="contentBold">
          Electronic Design Automation(EDA)
        </UiText>
        <UiText as="dd">
          Quartus Prime • Model Sim • Cadence • Synopsys • Vivado
        </UiText>
        <UiText as="dt" variant="contentBold">
          Circuit Simulation
        </UiText>
        <UiText as="dd">LTSpice • Logisim Evolution</UiText>
        <UiText as="dt" variant="contentBold">
          Computer Aided Design(CAD)
        </UiText>
        <UiText as="dd">Fusion 360 • Autodesk(Eagle) • FlatCam</UiText>
        <UiText as="dt" variant="contentBold">
          Computer Aided Manufacturing(CAM)
        </UiText>
        <UiText as="dd">
          CNC Machine(Subtractive Manufacturing) • 3D Printer(Additive
          Manufacturing)
        </UiText>
        <Heading level={2} as="h2" variant="h2">
          Hobbies
        </Heading>
        <UiText as="ul">
          <UiText as="li">✅ Learning</UiText>
          <UiText as="li">✅ Spend time with family</UiText>
          <UiText as="li">✅ Traveling</UiText>
          <UiText as="li">✅ TV • Video Game • Anime(Manga)</UiText>
        </UiText>
        {/* <h2>Projects</h2> */}
      </dl>
    </div>
  </Layout>
)

export default AboutPage
